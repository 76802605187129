/* eslint-disable no-unused-expressions */
import React, { Component, useEffect, useState } from 'react'

import CallIcon from '@mui/icons-material/Call'

import axios from 'axios'

import { CommonNotify } from '../../../common/CommonNotify'

import { Box, Typography } from '@mui/material'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getProfile } from '../../../actions/settings'
import { getConversations } from '../../../actions/conversation'
import { endpoints } from '../../../features/api/apiSlice'
import { getNumbers } from '../../../actions/numbers'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { SET_CURRENT_CHANNEL } from '../../../actions/types'

const NavNumbers = ({
  allConversations,
  getConversations,
  profile,
  getProfile,
  members,
  membersTeam,
  numbers,
  getNumbers,
  subScription,
  isShow,
  onClickHandle,
  currentChannel
}) => {
  const [allActiveConversations, setAllActiveConversations] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [conversations, setConversations] = useState([])
  const [sActiveConversations, setSActiveConversations] = useState([])
  const [dActiveConversations, setDActiveConversations] = useState([])
  const [activeConvoId, setActiveConvoId] = useState(null)
  const [sort, setSort] = useState('Newest')
  const [agentCode, setAgentCode] = useState(null)
  const [filter, setFilter] = useState('all')
  const [selectedValueNumber, setSelectedValueNumber] = useState('All')
  const [selectedTeam, setSelectedTeam] = useState('All')
  const [membersList, setMemberList] = useState([])
  const [numList, setNumList] = useState([])
  const [selectedIndex, setSelectedIndex] = useState([])

  const [page, setPage] = React.useState(0)
  const [response, setResponse] = useState({})
  const [activeChannel, setActiveChannel] = useState('')
  const dispatch = useDispatch()
  const [moreOpen, setMoreOpen] = useState(false)
  const navigate = useHistory()
  const location = navigate.location.pathname

  useEffect(() => {
    setMoreOpen('')
  }, [activeChannel])
  useEffect(() => {
    getConversations(page + 1)
    dispatch(endpoints.getConversations.initiate(page + 1))
    setActiveConvoId(null)
  }, [page])

  useEffect(() => {
    if (numbers) {
      setNumList(
        numbers.data.map((item, index) => {
          return {
            id: item?.id,
            text: item.phone_number,
            symbole: item.symbole,
            value: item.phone_number,
            key: index,
            name: item.name,
            friendlyName: item?.friendly_name
          }
        })
      )
    } else {
      getNumbers()
    }
  }, [numbers])

  useEffect(() => {
    if (allConversations) {
      handleFilters()
    } else {
      getConversations(page + 1)
    }
  }, [allConversations])

  useEffect(() => {
    // setActiveIndex(0)
  }, [])

  const getConvo = async id => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/conversation/${id}`
    const res = await axios.get(url)
    setResponse(prev => ({ ...prev, [id]: res }))
    return true
  }

  useEffect(() => {
    if (!activeConvoId) {
      const data1 = conversations?.map(data => {
        return getConvo(data?.id)
      })

      if (conversations?.length === data1?.length) {
        setActiveConvoId(conversations?.[0]?.id)
      }
    }
    setAllActiveConversations(
      conversations
        ?.filter(item => {
          if (item.state === 'active') {
            return item
          }
        })
        ?.sort((a, b) =>
          sort === 'Newest'
            ? new Date(b.last_message?.created_at || b?.created_at) -
              new Date(a.last_message?.created_at || a?.created_at)
            : new Date(a.last_message?.created_at || a?.created_at) -
              new Date(b.last_message?.created_at || b?.created_at)
        )
    )
    setSActiveConversations(
      conversations
        ?.filter(item => {
          if (item.state === 'snoozed') {
            return item
          }
        })
        ?.sort((a, b) =>
          sort === 'Newest'
            ? new Date(b.last_message?.created_at || b?.created_at) -
              new Date(a.last_message?.created_at || a?.created_at)
            : new Date(a.last_message?.created_at || a?.created_at) -
              new Date(b.last_message?.created_at || b?.created_at)
        )
    )
    setDActiveConversations(
      conversations
        ?.filter(item => {
          if (item.state === 'closed') {
            return item
          }
        })
        ?.sort((a, b) =>
          sort === 'Newest'
            ? new Date(b.last_message?.created_at || b?.created_at) -
              new Date(a.last_message?.created_at || a?.created_at)
            : new Date(a.last_message?.created_at || a?.created_at) -
              new Date(b.last_message?.created_at || b?.created_at)
        )
    )
  }, [conversations, sort])

  useEffect(() => {
    if (profile) {
      setAgentCode(profile?.data?.personal_link?.split('me/').pop())
    } else {
      getProfile()
    }
  }, [profile])

  useEffect(() => {
    handleFilters()
    setActiveConvoId(null)
  }, [filter, selectedValueNumber])

  useEffect(() => {
    processList()
  }, [members, membersTeam])

  const processList = () => {
    const allList = members ? [...members] : []

    const processList = allList.map(item => {
      return {
        key: item.first_name?.length ? item.id : item.id + '-team',
        id: item.first_name?.length ? item.id : item.id + '-team',
        value: item.id,
        display: item.first_name?.length
          ? item.first_name
          : item.name + ' team',
        text: item.first_name?.length ? item.first_name : item.name + ' team'
      }
    })
    const loggedInUser = localStorage.getItem('id')
    const newList = processList?.filter(item => Number(item.id))
    setMemberList(newList)
    // setMemberList(processList)
  }

  const handleFilters = () => {
    const agentId = parseInt(localStorage.getItem('id'))
    switch (filter) {
      case 'all':
        setConversations(
          allConversations?.conversations?.filter(
            el =>
              (el?.source === selectedValueNumber ||
                selectedValueNumber === 'All') &&
              (el.owner_id === selectedTeam || selectedTeam === 'All')
          )
        )
        setSelectedIndex(2)
        break
      case 'inbox':
        setConversations(
          allConversations.conversations?.filter(
            el =>
              el.owner_id === agentId &&
              (el?.source === selectedValueNumber ||
                selectedValueNumber === 'All') &&
              (el.owner_id === selectedTeam || selectedTeam === 'All')
          )
        )
        setSelectedIndex(0)
        break
      case 'unassigned':
        setConversations(
          allConversations.conversations?.filter(
            el =>
              !el.owner_id &&
              (el?.source === selectedValueNumber ||
                selectedValueNumber === 'All') &&
              (el.owner_id === selectedTeam || selectedTeam === 'All')
          )
        )
        setSelectedIndex(3)
        break
      case 'mentions':
        setConversations(
          allConversations.conversations?.filter(
            el =>
              el?.mentions?.length > 0 &&
              (el?.source === selectedValueNumber ||
                selectedValueNumber === 'All') &&
              (el.owner_id === selectedTeam || selectedTeam === 'All')
          )
        )
        setSelectedIndex(1)
        break

      default:
        setConversations(
          allConversations.conversations?.filter(
            el =>
              (el?.source === selectedValueNumber ||
                selectedValueNumber === 'All') &&
              (el.owner_id === selectedTeam || selectedTeam === 'All')
          )
        )
        setSelectedIndex(2)
        break
    }
  }
  useEffect(() => {
    dispatch({
      type: SET_CURRENT_CHANNEL,
      payload: numList?.[0]?.value
    })
  }, [numList])
  

  useEffect(() => {
    const data = allActiveConversations?.filter(obj => obj?.unread_messages > 0)
    if (data?.length > 0) {
      localStorage?.setItem('unread_message', data?.length)
    }
  }, [allActiveConversations])
  return (
    <div className="nav-numbers">
      <List component="div" disablePadding>
        {numList?.map((item, i) => (
          <ListItemButton
            key={i}
            sx={{ padding: '0px 15px', alignItems: 'start', color: 'white' }}
            className="sub-nav-numbers"
            selected={isShow && (selectedValueNumber === item.value  || currentChannel === item.value)}
            onClick={e => {
              setSelectedValueNumber(item.value)
              onClickHandle()
              dispatch({
                type: SET_CURRENT_CHANNEL,
                payload: item.value
              })
              navigate?.push('/conversation')
            }}
          >
            <div className="d-flex align-items-center">
              <ListItemIcon style={{ minWidth: '27px', color: 'white' }}>
                <CallIcon />
              </ListItemIcon>

              <ListItemText
                primary={
                  <Box display="flex" justifyContent="space-between">
                    {item.symbole && (
                      <Typography sx={{ color: 'white', fontSize: '12px' }}>
                        {item.symbole}
                      </Typography>
                    )}
                    <Typography sx={{ color: 'white', fontSize: '12px' }}>
                      {item?.friendlyName}
                    </Typography>
                  </Box>
                }
                secondary={
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                  >
                    <Typography sx={{ color: 'white', fontSize: '12px' }}>
                      {`${item.value} `}
                    </Typography>
                    <Typography
                      sx={{
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: 15,
                        padding: '0px 5px',
                        ml: 1,
                        fontSize: '12px'
                      }}
                    >
                      {allConversations?.sources?.[item?.value]}
                    </Typography>
                  </Box>
                }
              />
            </div>
            {/* {

                    activeChannel === item.value ?

                        <Typography style={{
                            textAlign: 'center',
                            marginLeft: 'auto',
                            marginRight: '0px',
                            display: 'flex',
                            position: 'relative'
                        }}>


                            <IconButton className='p-0' >
                                <Icon
                                    name="copy"
                                    onClick={() =>
                                        onCopyText(item.value)
                                    }
                                />
                            </IconButton>
                            <MoreHorizIcon onClick={(e) => setMoreOpen(e.currentTarget)} />
                            {moreOpen &&
                                <Menu
                                    onClose={() => setMoreOpen('')}
                                    style={{ position: 'absolute', top: 18 }}
                                    anchorEl={moreOpen}
                                >

                                    <Menu.Item
                                        active={moreOpen}

                                    >
                                        <a href={`/numbers/${item?.id}`}>Manage</a>
                                    </Menu.Item>

                                </Menu>
                            }
                        </Typography>

                        :
                 

                        <Typography


                            {...allConversations.conversations?.filter(
                                el => el?.source === item.value && item?.unread_messages > 0
                            )?.length > 0 ? ({
                                style: {
                                    width: '22px',
                                    textAlign: 'center',
                                    height: '22px',
                                    borderRadius: '50%',
                                    backgroundColor: '#f00',
                                    marginLeft: 'auto',
                                    color: '#ffffff',
                                    marginRight: '0px'
                                }
                            })


                                : ({
                                    style: {
                                        width: '22px',
                                        marginLeft: 'auto',
                                        marginRight: '0px',
                                        textAlign: 'center',
                                        height: '22px',
                                        borderRadius: '50%',
                                    }
                                })
                            }

                            onMouseEnter={() => setActiveChannel(item.value)}
                        >
                            {
                                allConversations.conversations?.filter(
                                    el => el?.source === item.value && item?.unread_messages > 0
                                )?.length > 0 ? allConversations.conversations?.filter(
                                    el => el?.source === item.value && item?.unread_messages > 0
                                )?.length : '  '
                            }
                        </Typography>


                } */}
          </ListItemButton>
        ))}
      </List>
    </div>
  )
}

const mapStateToProps = state => ({
  profile: state.settings.profile,
  numbers: state.numbers.numbers,
  allConversations: state.conversation.allConversations,
  subScription: state.home?.subscription?.data,
  currentChannel: state.home?.currentChannel
})

export default connect(mapStateToProps, {
  getProfile,
  getConversations,
  getNumbers
})(NavNumbers)
